import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import Modal from 'react-modal';
import ModalImage from "react-modal-image";
import { isMobile, isTablet, isBrowser, isSafari } from 'react-device-detect';

import Layout from "../components/layout"
import SEO from "../components/seo"

// import Airplane from "../components/airplane.js"
import ParallaxImage from "../components/parallaxImage.js"
import DemoButtonContainer from "../components/demo-button-container.js"
import NextgenContainer from "../components/nextgen-container.js"

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import "./modulePage.scss";
import Webinair from '../components/webinair';

let customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '20px 20px 35px',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    borderRadius: '20px',
    backgroundColor: '#00357d',
    border: 0,
    color: 'white'
  }
};

if (isMobile && isTablet === false) {
  customStyles.content.width = '300px';
}

if (isMobile && isTablet) {
  customStyles.content.width = '600px';
}

let previousScroll = 0;

const refsArray = [];

let mobileImage = '';
let tabletImage = '';
let desktopImage = '';

let imageType = '';

let vtbDemo = null;

const cleanup = () => {
  document.documentElement.style.overflow = 'unset';
}

const checkUrl = (slug) => {
  if (slug === 'mediaspirit' || slug === 'website' || slug === 'visual-tour-builder') {
    return true;
  }
  return false;
}

const nl2br = (str, is_xhtml) => {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

const ModulePage = (queryData) => {
  const [previousModule, setPreviousModule] = useState(null);
  const [nextModule, setNextModule] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [moduleData, setModuleData] = useState(null);
  const [moduleSlug, setModuleSlug] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState([]);
  const [phoneImage, setPhoneImage] = useState(null);
  const [endContainer, setEndContainer] = useState(null);
  const [flexiVid, setFlexiVid] = useState(false);

  const onScroll = () => {
    if (modalIsOpen) {
      if (previousScroll !== window.scrollY) {
        handleCloseModal();
      }
      previousScroll = window.scrollY;
    }
  }

  const handleOpenModal = (module) => {
    setModuleSlug(module.slug);
    setModuleData(module);
    setIsOpen(true);
    previousScroll = window.scrollY;
  }

  const handleCloseModal = () => {
    setModuleSlug(null);
    setModuleData(null);
    setIsOpen(false);
  }

  const findModules = (currentModuleSlug, modules) => {
    const allModules = [];

    for (const modulessArray of modules) {
      for (const module of modulessArray.modules) {
        allModules.push(module);
      }
    }

    for (let i = 0; i < allModules.length; i++) {
      if (allModules[i].slug === currentModuleSlug) {
        if (i !== 0 && i !== allModules.length - 1) {
          setPreviousModule(allModules[i - 1]);
          setNextModule(allModules[i + 1]);
        } else if (i === 0) {
          setPreviousModule(allModules[allModules.length - 1]);
          setNextModule(allModules[i + 1]);
        } else if (i === allModules.length - 1) {
          setPreviousModule(allModules[i - 1]);
          setNextModule(allModules[0]);
        }
        break;
      }
    }
  }

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: "center" });
    } else {
      if (ref && ref.offsetTop && ref.offsetHeight) {
        window.scrollTo(0, ref.offsetTop + 60);
      }
    }
  }

  if (queryData.data && queryData.data.gcms && queryData.data.gcms.backgroundImages) {
    let backgroundImages = queryData.data.gcms.backgroundImages;
    for (const image of backgroundImages) {
      if (image.indicator === 'home-customer-journey-buttons-background') {
        if (image.deviceType === 'mobile' && (image.backgroundImage != null && image.backgroundImage !== '')) {
          mobileImage = image.backgroundImage;
        }
        if (image.deviceType === 'tablet' && (image.backgroundImage != null && image.backgroundImage !== '')) {
          tabletImage = image.backgroundImage;
        }
        if (image.deviceType === 'desktop' && (image.backgroundImage != null && image.backgroundImage !== '')) {
          desktopImage = image.backgroundImage;
        }
      }
    }
  }

  let moduleGroups = []
  let moduleTableData = null;
  let url = queryData.uri;
  let lastParam = url.substr(url.lastIndexOf('/') + 1).toLowerCase();

  if (queryData.data && queryData.data.gcms && queryData.data.gcms.contentModuleGroups) {
    moduleGroups = [];
    for (const moduleGroup of queryData.data.gcms.contentModuleGroups) {
      if (moduleGroup.indicatorModuleName === lastParam) {
        moduleGroups.push(moduleGroup);
      }
    }
  }

  switch (lastParam) {
    case 'nieuwsbrief':
      if (queryData.data.allGoogleDataNewsletter && queryData.data.allGoogleDataNewsletter.edges && queryData.data.allGoogleDataNewsletter.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataNewsletter.edges;
      }
      break;
    case 'crm':
      if (queryData.data.allGoogleDataCrm && queryData.data.allGoogleDataCrm.edges && queryData.data.allGoogleDataCrm.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataCrm.edges;
      }
      break;
    case 'travelplan':
      if (queryData.data.allGoogleDataTravelplans && queryData.data.allGoogleDataTravelplans.edges && queryData.data.allGoogleDataTravelplans.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataTravelplans.edges;
      }
      break;
    case 'todo':
      if (queryData.data.allGoogleDataTodo && queryData.data.allGoogleDataTodo.edges && queryData.data.allGoogleDataTodo.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataTodo.edges;
      }
      break;
    case 'trustpilot':
      if (queryData.data.allGoogleDataTrustPilot && queryData.data.allGoogleDataTrustPilot.edges && queryData.data.allGoogleDataTrustPilot.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataTrustPilot.edges;
      }
      break;
    case 'chat':
      if (queryData.data.allGoogleDataChat && queryData.data.allGoogleDataChat.edges && queryData.data.allGoogleDataChat.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataChat.edges;
      }
      break;
    case 'telefoonkoppeling':
      if (queryData.data.allGoogleDataVoip && queryData.data.allGoogleDataVoip.edges && queryData.data.allGoogleDataVoip.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataVoip.edges;
      }
      break;
    case 'documenten':
      if (queryData.data.allGoogleDataDocumenten && queryData.data.allGoogleDataDocumenten.edges && queryData.data.allGoogleDataDocumenten.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataDocumenten.edges;
      }
      break;
    case 'emails versturen':
      if (queryData.data.allGoogleDataEMailsVersturen && queryData.data.allGoogleDataEMailsVersturen.edges && queryData.data.allGoogleDataEMailsVersturen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataEMailsVersturen.edges;
      }
      break;
    case 'emails inlezen':
      if (queryData.data.allGoogleDataEMailsInlezen && queryData.data.allGoogleDataEMailsInlezen.edges && queryData.data.allGoogleDataEMailsInlezen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataEMailsInlezen.edges;
      }
      break;
    case 'basis':
      if (queryData.data.allGoogleDataBasis && queryData.data.allGoogleDataBasis.edges && queryData.data.allGoogleDataBasis.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataBasis.edges;
      }
      break;
    case 'sms':
      if (queryData.data.allGoogleDataSms && queryData.data.allGoogleDataSms.edges && queryData.data.allGoogleDataSms.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataSms.edges;
      }
      break;
    case 'mail-in':
      if (queryData.data.allGoogleDataEMailsInlezen && queryData.data.allGoogleDataEMailsInlezen.edges && queryData.data.allGoogleDataEMailsInlezen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataEMailsInlezen.edges;
      }
      break;
    case 'mail-out':
      if (queryData.data.allGoogleDataEMailsVersturen && queryData.data.allGoogleDataEMailsVersturen.edges && queryData.data.allGoogleDataEMailsVersturen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataEMailsVersturen.edges;
      }
      break;
    case 'todo-workflow':
      if (queryData.data.allGoogleDataTodoWorkflow && queryData.data.allGoogleDataTodoWorkflow.edges && queryData.data.allGoogleDataTodoWorkflow.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataTodoWorkflow.edges;
      }
      break;
    case 'reporting':
      if (queryData.data.allGoogleDataReporting && queryData.data.allGoogleDataReporting.edges && queryData.data.allGoogleDataReporting.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataReporting.edges;
      }
      break;
    case 'betaalinfo':
      if (queryData.data.allGoogleDataBetaalinfo && queryData.data.allGoogleDataBetaalinfo.edges && queryData.data.allGoogleDataBetaalinfo.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataBetaalinfo.edges;
      }
      break;
    case 'betaallink':
      if (queryData.data.allGoogleDataBetaallink && queryData.data.allGoogleDataBetaallink.edges && queryData.data.allGoogleDataBetaallink.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataBetaallink.edges;
      }
      break;
    case 'annuleringen':
      if (queryData.data.allGoogleDataAnnuleringen && queryData.data.allGoogleDataAnnuleringen.edges && queryData.data.allGoogleDataAnnuleringen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataAnnuleringen.edges;
      }
      break;
    case 'verzekeringen':
      if (queryData.data.allGoogleDataVerzekeringen && queryData.data.allGoogleDataVerzekeringen.edges && queryData.data.allGoogleDataVerzekeringen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataVerzekeringen.edges;
      }
      break;
    case 'pnr-iata':
      if (queryData.data.allGoogleDataPnrIata && queryData.data.allGoogleDataPnrIata.edges && queryData.data.allGoogleDataPnrIata.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataPnrIata.edges;
      }
      break;
    case 'accounting':
      if (queryData.data.allGoogleDataAccounting && queryData.data.allGoogleDataAccounting.edges && queryData.data.allGoogleDataAccounting.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataAccounting.edges;
      }
      break;
    case 'boekingsformulier':
      if (queryData.data.allGoogleDataBoekingsformulier && queryData.data.allGoogleDataBoekingsformulier.edges && queryData.data.allGoogleDataBoekingsformulier.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataBoekingsformulier.edges;
      }
      break;
    case 'planboard':
      if (queryData.data.allGoogleDataPlanboard && queryData.data.allGoogleDataPlanboard.edges && queryData.data.allGoogleDataPlanboard.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataPlanboard.edges;
      }
      break;
    case 'maps':
      if (queryData.data.allGoogleDataMaps && queryData.data.allGoogleDataMaps.edges && queryData.data.allGoogleDataMaps.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataMaps.edges;
      }
      break;
    case 'groepsreizen':
      if (queryData.data.allGoogleDataGroepsreizen && queryData.data.allGoogleDataGroepsreizen.edges && queryData.data.allGoogleDataGroepsreizen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataGroepsreizen.edges;
      }
      break;
    case 'kantoren-afdelingen':
      if (queryData.data.allGoogleDataKantoren && queryData.data.allGoogleDataKantoren.edges && queryData.data.allGoogleDataKantoren.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataKantoren.edges;
      }
      break;
    case 'bi-business-intelligence-software':
      if (queryData.data.allGoogleDataBi && queryData.data.allGoogleDataBi.edges && queryData.data.allGoogleDataBi.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataBi.edges;
      }
      break;
    case 'meertalig':
      if (queryData.data.allGoogleDataMeertalig && queryData.data.allGoogleDataMeertalig.edges && queryData.data.allGoogleDataMeertalig.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataMeertalig.edges;
      }
      break;
    case 'commissie':
      if (queryData.data.allGoogleDataCommissie && queryData.data.allGoogleDataCommissie.edges && queryData.data.allGoogleDataCommissie.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataCommissie.edges;
      }
      break;
    case 'recurring':
      if (queryData.data.allGoogleDataRecurring && queryData.data.allGoogleDataRecurring.edges && queryData.data.allGoogleDataRecurring.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataRecurring.edges;
      }
      break;
    case 'passolution':
      if (queryData.data.allGoogleDataPassolution && queryData.data.allGoogleDataPassolution.edges && queryData.data.allGoogleDataPassolution.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataPassolution.edges;
      }
      break;
    case 'mobile-app':
      if (queryData.data.allGoogleDataMobileApp && queryData.data.allGoogleDataMobileApp.edges && queryData.data.allGoogleDataMobileApp.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataMobileApp.edges;
      }
      break;
    case 'visual-tour-builder':
      if (queryData.data.allGoogleDataVtb && queryData.data.allGoogleDataVtb.edges && queryData.data.allGoogleDataVtb.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataVtb.edges;
      }
      break;
    case 'mediaspirit':
      if (queryData.data.allGoogleDataMediaSpirit && queryData.data.allGoogleDataMediaSpirit.edges && queryData.data.allGoogleDataMediaSpirit.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataMediaSpirit.edges;
      }
      break;
    case 'inkoop-kostprijzen':
      if (queryData.data.allGoogleDataInkoopKostprijzen && queryData.data.allGoogleDataInkoopKostprijzen.edges && queryData.data.allGoogleDataInkoopKostprijzen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataInkoopKostprijzen.edges;
      }
      break;
    case 'inkoop-bestellingen':
      if (queryData.data.allGoogleDataInkoopBestellingen && queryData.data.allGoogleDataInkoopBestellingen.edges && queryData.data.allGoogleDataInkoopBestellingen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataInkoopBestellingen.edges;
      }
      break;
    case 'inkoop-facturen':
      if (queryData.data.allGoogleDataInkoopFacturen && queryData.data.allGoogleDataInkoopFacturen.edges && queryData.data.allGoogleDataInkoopFacturen.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataInkoopFacturen.edges;
      }
      break;
    case 'allotment-stopsales':
      if (queryData.data.allGoogleDataInkoopAllotment && queryData.data.allGoogleDataInkoopAllotment.edges && queryData.data.allGoogleDataInkoopAllotment.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataInkoopAllotment.edges;
      }
      break;
    case 'website':
      if (queryData.data.allGoogleDataWebsite && queryData.data.allGoogleDataWebsite.edges && queryData.data.allGoogleDataWebsite.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataWebsite.edges;
      }
      break;
    case 'online-boeken':
      if (queryData.data.allGoogleDataOnlineBoeken && queryData.data.allGoogleDataOnlineBoeken.edges && queryData.data.allGoogleDataOnlineBoeken.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataOnlineBoeken.edges;
      }
      break;
    case 'retail-b2b':
      if (queryData.data.allGoogleDataRetailB2B && queryData.data.allGoogleDataRetailB2B.edges && queryData.data.allGoogleDataRetailB2B.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataRetailB2B.edges;
      }
      break;
    case 'mijn-omgeving':
      if (queryData.data.allGoogleDataMijnOmgeving && queryData.data.allGoogleDataMijnOmgeving.edges && queryData.data.allGoogleDataMijnOmgeving.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataMijnOmgeving.edges;
      }
      break;
    case 'whooz':
      if (queryData.data.allGoogleDataWhooz && queryData.data.allGoogleDataWhooz.edges && queryData.data.allGoogleDataWhooz.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataWhooz.edges;
      }
      break;
    case 'beddenbanken':
      if (queryData.data.allGoogleDataBeddenbanken && queryData.data.allGoogleDataBeddenbanken.edges && queryData.data.allGoogleDataBeddenbanken.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataBeddenbanken.edges;
      }
      break;
    case 'surveys':
      if (queryData.data.allGoogleDataSurveys && queryData.data.allGoogleDataSurveys.edges && queryData.data.allGoogleDataSurveys.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataSurveys.edges;
      }
      break;
    case 'online-offerte':
      if (queryData.data.allGoogleDataOnlineOfferte && queryData.data.allGoogleDataOnlineOfferte.edges && queryData.data.allGoogleDataOnlineOfferte.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataOnlineOfferte.edges;
      }
      break;
    case 'agenda':
      if (queryData.data.allGoogleDataAgenda && queryData.data.allGoogleDataAgenda.edges && queryData.data.allGoogleDataAgenda.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataAgenda.edges;
      }
      break;
    case 'co2-footprint':
      if (queryData.data.allGoogleDataCo2Footprint && queryData.data.allGoogleDataCo2Footprint.edges && queryData.data.allGoogleDataCo2Footprint.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataCo2Footprint.edges;
      }
      break;
    case 'efactuur-ubl':
      if (queryData.data.allGoogleDataEFactuurUbl && queryData.data.allGoogleDataEFactuurUbl.edges && queryData.data.allGoogleDataEFactuurUbl.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataEFactuurUbl.edges;
      }
      break;
    case 'reisbureau-regeling':
      if (queryData.data.allGoogleDataReisbureauregeling && queryData.data.allGoogleDataReisbureauregeling.edges && queryData.data.allGoogleDataReisbureauregeling.edges.length > 0) {
        moduleTableData = queryData.data.allGoogleDataReisbureauregeling.edges;
      }
      break;
    default:
      break;
  }
  let moduleTable = null;
  if (moduleTableData != null) {
    moduleTable = moduleTableData.map((feature, index) => {
      return (
        <tr key={index}>
          <td className="extra-padding">{feature.node.feature}</td>
          <td className="center">
            {(feature.node.economy && feature.node.economy.length < 2)
              ?
              [
                (feature.node.economy === '✓' && <span key={"economy-green" + index} className="green-icon">{feature.node.economy}</span>),
                (feature.node.economy === '✘' && <span key={"economy-red" + index} className="red">{feature.node.economy}</span>)
              ]
              : (feature.node.economy === "optioneel" ? <span className="orange">{feature.node.economy}</span> : feature.node.economy)
            }
          </td>
          <td className="center">
            {(feature.node.comfort && feature.node.comfort.length < 2)
              ?
              [
                (feature.node.comfort === '✓' && <span key={"comfort-green" + index} className="green-icon">{feature.node.comfort}</span>),
                (feature.node.comfort === '✘' && <span key={"comfort-red" + index} className="red">{feature.node.comfort}</span>)
              ]
              : (feature.node.comfort === "optioneel" ? <span className="orange">{feature.node.comfort}</span> : feature.node.comfort)
            }
          </td>
          <td className="center">
            {(feature.node.business && feature.node.business.length < 2)
              ?
              [
                (feature.node.business === '✓' && <span key={"business-green" + index} className="green-icon">{feature.node.business}</span>),
                (feature.node.business === '✘' && <span key={"business-red" + index} className="red">{feature.node.business}</span>)
              ]
              : (feature.node.business === "optioneel" ? <span className="orange">{feature.node.business}</span> : feature.node.business)
            }
          </td>
          <td className="center">
            {(feature.node.first && feature.node.first.length < 2)
              ?
              [
                (feature.node.first === '✓' && <span key={"first-green" + index} className="green-icon">{feature.node.first}</span>),
                (feature.node.first === '✘' && <span key={"first-red" + index} className="red">{feature.node.first}</span>)
              ]
              : (feature.node.first === "optioneel" ? <span className="orange">{feature.node.first}</span> : feature.node.first)
            }
          </td>
        </tr>
      );
    });
  }

  // if (queryData.data.allDemio && queryData.data.allDemio.edges) {
  //   for (const webinar of queryData.data.allDemio.edges) {
  //     if (webinar.node.name.toLowerCase().indexOf('visual tour builder') > -1) {
  //       vtbDemo = webinar.node;
  //       break;
  //     }
  //   }
  // }

  const module = queryData.data.gcms.module;
  let title = '';
  let description = '';
  let metaDescription = '';
  let slug = '';
  if (module != null) {
    if (module.descriptionTitle != null) {
      title = module.descriptionTitle;
    }
    if (module.slug != null) {
      slug = module.slug;
    }
    if (module.metaDescription != null) {
      metaDescription = module.metaDescription;
    }
    if (module.description != null && module.description.html != null) {
      description = nl2br(module.description.html);
    }
    if (module.imageUrl != null && module.imageUrl !== '') {
      if (module.imageUrl.indexOf('videos.sproutvideo') > -1) {
        imageType = 'sprout';
      } else if (module.imageUrl.indexOf('media.') > -1) {
        imageType = 'mediaspirit';
      } else {
        imageType = 'random';
      }
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener('scroll', onScroll, true);
  }

  useEffect(() => {
    // setUrlParam(window.location.pathname.replace(/\//g, ''));
    if (queryData.data && queryData.data.gcms && queryData.data.gcms.submenuModules && queryData.data.gcms.submenuModules[0] && queryData.data.gcms.submenuModules[0].modules) {
      findModules(lastParam, queryData.data.gcms.submenuModules);
    }
    Modal.setAppElement('body');
    if (isBrowser) {
      setMobile(true);
    } else {
      setMobile(false);
    }

    if (isMobile && isTablet === false && isBrowser === false) {
      if (mobileImage !== '') {
        setBackgroundImage(mobileImage);
      } else if (desktopImage !== '') {
        setBackgroundImage(desktopImage);
      } else if (tabletImage !== '') {
        setBackgroundImage(tabletImage);
      }
    } else if (isMobile && isTablet && isBrowser === false) {
      if (tabletImage !== '') {
        setBackgroundImage(tabletImage);
      } else if (mobileImage !== '') {
        setBackgroundImage(mobileImage);
      } else if (desktopImage !== '') {
        setBackgroundImage(mobileImage);
      }
    } else {
      if (desktopImage !== '') {
        setBackgroundImage(desktopImage);
      } else if (tabletImage !== '') {
        setBackgroundImage(tabletImage);
      } else if (mobileImage !== '') {
        setBackgroundImage(mobileImage);
      }
    }

    if (isSafari === true) {
      setPhoneImage("https://media.travelspirit.nl/original/sm/phone-image.png");
    } else {
      setPhoneImage("https://media.travelspirit.nl/original/smw/phone-image.webp");
    }

    if (lastParam === 'visual-tour-builder' && vtbDemo != null) {
      setEndContainer(
        <span>
          <div className="vtb-webinair-container">
            <Webinair key={vtbDemo.name} data={vtbDemo} type="demio" />
          </div>
          <div className="modules-demo-container">
            <NextgenContainer titles={queryData.data.gcms.titles} />
          </div>
        </span>
      )
    } else if (lastParam === 'visual-tour-builder' || lastParam === 'mediaspirit' || lastParam === 'mijn-omgeving' || lastParam === 'crm') {
      setEndContainer(
        <div className="modules-demo-container">
          <NextgenContainer titles={queryData.data.gcms.titles} />
        </div>
      )
    } else {
      setEndContainer(
        <div className="modules-demo-container">
          <DemoButtonContainer url={lastParam + '-wrong'} titles={queryData.data.gcms.titles} />
        </div>
      )
    }

    if (lastParam === 'mobile-app') {
      setFlexiVid(true);
    } else {
      setFlexiVid(false);
    }

    return () => {

    }
  }, [mobile, lastParam, queryData.data, flexiVid]);

  return (
    <Layout>
      <SEO title={title} description={metaDescription} urlParams={"modules/" + slug} />
      <img className="print print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
      {previousModule && <Link className="previous-module" key={previousModule.slug} to={`/modules/${previousModule.slug}`}>
        <div className="icon baseline">
          <ArrowBackIcon />
        </div>
        {previousModule.moduleName}
      </Link>
      }
      {nextModule && <Link className="next-module" key={nextModule.slug} to={`/modules/${nextModule.slug}`}>{nextModule.moduleName}
        <div className="icon baseline">
          <ArrowForwardIcon />
        </div>
      </Link>
      }

      <div className="module-page-container airplane-wrapper">
        {checkUrl(lastParam) === true && <div>
          <div className="summary-container container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div id="summary-menu-container">
              <ul className="summary-menu">
                {moduleGroups.map((moduleGroup, i) => {
                  return <li key={moduleGroup.slug + 'ref'}>
                    <button className="orange-button" onClick={() => scrollToRef(refsArray[i])}>{moduleGroup.title}</button>
                  </li>
                })}
              </ul>
            </div>
          </div>
          {/* {airplaneRefs.length === moduleGroups.length &&
            <Airplane blocks={airplaneRefs} />
          } */}
          {moduleGroups.map((moduleGroup, i) => {
            return <div id={moduleGroup.slug + "-container"} className={moduleGroup.containerColor + "-container container"} ref={ref => {
              refsArray[i] = ref;
              // if (airplaneRefs.indexOf(moduleGroup.title + "-container") < 0) {
              //   setAirplaneRefs(airplaneRefs.concat([moduleGroup.title + "-container"]));
              // }
            }} key={moduleGroup.slug}>
              {mobile === true ? (
                <div className="print-ignore row justify-content-between">
                  {i % 2 === 1 && (
                    <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn parallax-container"}>
                      <ParallaxImage slug={moduleGroup.slug} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} redirectUrl={moduleGroup.redirectUrl} clickable={true} />
                    </div>
                  )}
                  <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                    <div className="row">
                      {/* <span className="counter">{('0' + (i + 1)).slice(-2)}</span> */}
                      <h1>{moduleGroup.title}</h1>
                      <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleGroup.description.html }} />
                      {moduleGroup.modules && moduleGroup.modules.length > 0 && <span className="module-list">
                        <h2>Modules:</h2>
                        <hr />
                        <ul className="module-list">
                          {moduleGroup.modules.map((module, index) => {
                            if (index > 9) return null
                            return <li className="module-list-item" key={module.slug}>
                              <button onClick={() => handleOpenModal(module)}>
                                {module.moduleName}
                              </button>
                            </li>
                          })}
                        </ul>
                      </span>}
                    </div>
                  </div>
                  {i % 2 === 0 && (
                    <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn parallax-container"}>
                      <ParallaxImage slug={moduleGroup.slug} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} redirectUrl={moduleGroup.redirectUrl} clickable={true} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="print-ignore row justify-content-between row-fadeIn">
                  <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                    <div className="row">
                      {/* <span className="counter">{('0' + (i + 1)).slice(-2)}</span> */}
                      <h1>{moduleGroup.title}</h1>
                      <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleGroup.description.html }} />
                      {moduleGroup.modules && moduleGroup.modules.length > 0 && <span className="module-list">
                        <h2>Modules:</h2>
                        <hr />
                        <ul className="module-list">
                          {moduleGroup.modules.map((module, index) => {
                            if (index > 9) return null
                            return <li className="module-list-item" key={module.slug}>
                              <button onClick={() => handleOpenModal(module)}>
                                {module.moduleName}
                              </button>
                            </li>
                          })}
                        </ul>
                      </span>}
                    </div>
                  </div>
                  <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                    <div className="parallax-container">
                      <ParallaxImage slug={moduleGroup.slug} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} redirectUrl={moduleGroup.redirectUrl} clickable={true} />
                    </div>
                  </div>
                </div>
              )}
              <div className="print row justify-content-between row-fadeIn">
                <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                  <div className="row">
                    {/* <span className="counter">{('0' + (i + 1)).slice(-2)}</span> */}
                    <h1>{moduleGroup.title}</h1>
                    <div className="html-text inline" dangerouslySetInnerHTML={{ __html: moduleGroup.description.html }} />
                    {moduleGroup.modules && moduleGroup.modules.length > 0 && <span className="module-list">
                      <h2>Modules:</h2>
                      <hr />
                      <ul className="module-list">
                        {moduleGroup.modules.map((module, index) => {
                          if (index > 9) return null
                          return <li className="module-list-item" key={module.slug}>
                            <button onClick={() => handleOpenModal(module)}>
                              {module.moduleName}
                            </button>
                          </li>
                        })}
                      </ul>
                    </span>}
                  </div>
                </div>
                <div className={"col-sm-5 " + moduleGroup.slug + "-fadeIn"}>
                  <div className="parallax-container">
                    <ParallaxImage slug={moduleGroup.slug} timeline={moduleGroup.parallaxAnimationType} displayType={moduleGroup.parallaxDisplayType} images={moduleGroup} redirectUrl={moduleGroup.redirectUrl} clickable={true} />
                  </div>
                </div>
              </div>
            </div>
          })}
          <Modal isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            style={customStyles}
          >
            {moduleData && moduleData.moduleTooltip && moduleData.moduleName && (
              <div>
                <h1>{moduleData.moduleName}</h1>
                <p>{moduleData.moduleTooltip}</p>
                <Link className="modal-module-reference" key={moduleSlug} onClick={() => cleanup(moduleSlug)} to={`/modules/${moduleSlug}`}>Meer over {moduleData.moduleName}
                  <div className="icon baseline">
                    <ArrowRightAltIcon />
                  </div>
                </Link>
              </div>
            )}
          </Modal>
        </div>}
      </div>

      <div className="module-container container">
        <h1>{title}</h1>
        <div className="row">
          <div className="col-sm">
            <div className={"html-text " + (module && module.imageUrl && "image")} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          {module && module.imageUrl ? <div className="col-sm-5">
            {imageType === 'mediaspirit' &&
              <ModalImage
                small={module.imageUrl}
                large={module.imageUrl}
              />
            }
            {imageType === 'sprout' && <span>
              {flexiVid === true && <div className="sprout-video-module-container">
                <iframe className='sproutvideo-player lazyload' title="module video" src={module.imageUrl} style={{ position: 'absolute', minWidth: "213px", minHeight: "444px", width: "213px", height: "444px", left: '11px', top: '17px' }} frameBorder='0' allowFullScreen></iframe>
                {phoneImage && <img minwidth="213px" minheight="444px" width="236px" height="475px" src={phoneImage} className="attachment-section-text-image size-section-text-image" alt="phone" sizes="(max-width: 236px) 100vw, 236px"></img>}
              </div>}
              {flexiVid === false && <div style={{ position: 'relative', height: 0, paddingBottom: '56.25%' }}>
                <iframe className='sproutvideo-player lazyload' title="module video" src={module.imageUrl} style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }} frameBorder='0' allowFullScreen>
                </iframe>
              </div>}
            </span>}
            {imageType === 'random' && <div>
              <iframe title="module video" src={module.imageUrl} style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }} frameBorder='0' allowFullScreen></iframe>
            </div>}
          </div> : ''}
        </div>

        {moduleTable != null ? <table className="module-table">
          <thead>
            <tr>
              <td aria-label="empty 1st cell"></td>
              <td className="center"><b>Economy</b></td>
              <td className="center"><b>Comfort</b></td>
              <td className="center"><b>Business</b></td>
              <td className="center"><b>First</b></td>
            </tr>
          </thead>
          <tbody>{moduleTable}</tbody>
        </table> : <span></span>}
      </div>
      {endContainer}
    </Layout>
  )
}

export default ModulePage

export const pageQuery = graphql`
query ModulePageQuery($id: ID) {
  gcms {
    module(where: { id: $id }) {
      slug
      descriptionTitle
      description { html }
      metaDescription
      imageUrl
    }
    submenuModules {
      modules {
        moduleName
        slug
      }
    }
    titles {
        indicator
        value
        richValue { html }
    }
    contentModuleGroups(orderBy: priority_ASC) {
      indicatorModuleName
      title
      description { html }
      slug
      modules {
        moduleTooltip
        moduleName
        slug
      }
      containerColor
      parallaxDisplay
      parallaxAnimation
      parallaxImage1
      parallaxImage2
      parallaxImage3
      redirectUrl
    }
    backgroundImages {
      indicator
      deviceType
      backgroundImage
      parallaxDisplayType
      parallaxAnimationType
      parallaxImage1
      parallaxImage2
      parallaxImage3
    }
  }
  # allDemio(sort: {fields: timestamp}, filter: {status: {ne: "finished"}}) {
  #   edges {
  #     node {
  #       name
  #       registration_url
  #       description
  #       timestamp
  #       zone
  #     }
  #   }
  # }
  allGoogleDataCrm {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataNewsletter {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataDocumenten {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataEMailsInlezen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataTrustPilot {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataTodo {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataEMailsVersturen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataChat {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataTravelplans {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataVoip {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataBasis {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataSms {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataEMailsInlezen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataEMailsVersturen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataTrustPilot {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataTodoWorkflow {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataReporting {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataBetaalinfo {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataBetaallink {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataAnnuleringen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataVerzekeringen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataPnrIata {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataAccounting {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataBoekingsformulier {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataPlanboard {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataMaps {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataGroepsreizen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataKantoren {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataBi {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataMeertalig {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataCommissie {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataRecurring {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataPassolution {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataMobileApp {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataVtb {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataMediaSpirit {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataInkoopKostprijzen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataInkoopFacturen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataInkoopBestellingen {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataInkoopAllotment {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataWebsite {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataOnlineBoeken {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataRetailB2B {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataMijnOmgeving {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataWhooz {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataBeddenbanken {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataSurveys {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataOnlineOfferte {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataAgenda {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataCo2Footprint {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataEFactuurUbl {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
  allGoogleDataReisbureauregeling {
    edges {
      node {
        comfort
        economy
        first
        business
        feature
      }
    }
  }
}`;
